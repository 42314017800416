<template>
  <div class="second-layout">
    <Header />
    <slot />
    <Footer />
  </div>

</template>

<script>
import { defineComponent } from 'vue'
import Header from '@/components/molecules/UserHeader.vue'
import Footer from '@/components/molecules/FooterUT.vue'

export default defineComponent({
  name: 'SecondLayout',
  components: {
    Header,
    Footer
  }
})
</script>

<style lang="scss" scoped>
.second-layout {
  background: #fff;

  .header {
    padding: 2% 5% 0 5%;
  }
}

@media (max-width: $break-md) {
  .second-layout {
    .header {
      padding: 2% 3% 0 3%;
    }
  }
}
</style>
