
import { defineComponent } from 'vue'
import DefaultPopup from '@/components/molecules/DefaultPopup.vue'
import { Form, Field, ErrorMessage } from 'vee-validate'
import * as yup from 'yup'

export default defineComponent({
  name: 'PasswordForgotSection',
  data () {
    const schema = yup.object().shape({
      email: yup.string().required('Email is required!')
    })

    return {
      isModalOpen: false,
      schema,
      translateData: {},
      dataPopup: ''
    }
  },
  components: {
    DefaultPopup,
    Form,
    Field,
    ErrorMessage
  },
  mounted () {
    this.translate()
  },
  methods: {
    translate (data) {
      data = []
      data.push('forgot_password_header', 'forgot_password_sub_header', 'send_button', 'forgot_password_popup_1', 'registration_popup_2', 'registration_popup_3')
      this.$store.dispatch('getPayments/translate', data).then(
        (response) => {
          this.translateData = response
          this.dataPopup = this.translateData.forgot_password_popup_1 + ' ' + this.translateData.registration_popup_2 + ' ' + this.translateData.registration_popup_3
        },
        (error) => {
          console.log(error)
        }
      )
    },
    sendEmail (data) {
      this.$store.dispatch('auth/passwordRecoveryEmail', data)
      this.isModalOpen = true
    },
    handleCloseModal () {
      this.$router.push('/')
    }
  }
})
