
import { defineComponent } from 'vue'
import Main from '@/templates/SecondLayout.vue'
import PasswordForgotSection from '@/components/sections/PasswordForgotSection.vue'

export default defineComponent({
  name: 'PasswordRecovery',
  components: {
    Main,
    PasswordForgotSection
  }
})
