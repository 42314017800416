import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-41f75e66"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "popup__container" }
const _hoisted_2 = { class: "popup__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CloseIcon = _resolveComponent("CloseIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: "popup",
    onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleCloseModal && _ctx.handleCloseModal(...args)), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: "popup__close",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleCloseModal && _ctx.handleCloseModal(...args)))
      }, [
        _createVNode(_component_CloseIcon)
      ]),
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.message), 1),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ]))
}